// ChatInput.jsx

import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChurch, faCopyright } from '@fortawesome/free-solid-svg-icons';
import styles from './Chat.module.css';

const ChatInput = ({ sendMessage }) => {
  const [input, setInput] = useState('');

  const handleKeyPress = (e) => {
    // Check if Enter key is pressed
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior of Enter key in textarea
      console.log('Enter key pressed');
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (input.trim() === '') return;
    console.log('Sending message:', input);
    sendMessage(input);
    setInput('');
    console.log('Input cleared');
  };

  return (
    <div className={styles.chatInputContainer}>
      <div className={styles.poweredBy}>
        <a href="https://agentura.ai" target="_blank" rel="noopener noreferrer">
          powered by agentura.ai <FontAwesomeIcon icon={faCopyright} /> 2024
        </a>
      </div>
      <div className={styles.chatInput}>
        <TextareaAutosize
          value={input}
          onChange={(e) => {
            console.log('Input changed:', e.target.value);
            setInput(e.target.value);
          }}
          onKeyPress={handleKeyPress} // Call handleKeyPress on key press
          placeholder="Type a message..."
          className={`${styles.chatInputField} ${styles.rajdhaniFont}`} // Apply rajdhaniFont class
          minRows={1}
          maxRows={6}
        />
        <button onClick={handleSendMessage} className={styles.chatButton}>
          <FontAwesomeIcon icon={faChurch} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;