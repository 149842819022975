// App.jsx
import React from 'react';
import Chat from '../Chat/Chat';
import Thread from '../Thread/Thread';

function App() {
  return (
    <div className="App">
      <Thread />
      <Chat />
    </div>
  );
}

export default App;
