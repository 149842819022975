import React, { useState } from 'react';
import styles from './Thread.module.css'; // Import CSS module for styling

const Thread = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.threadContainer}>
      <div className={styles.threadHeader} onClick={toggleExpand}>
        Paul the Apostle
      </div>
      {isExpanded && (
        <div className={styles.threadContent}>
          {/* 
          <div className={styles.threadItem}>Thread 1: Placeholder content</div>
          <div className={styles.threadItem}>Thread 2: Placeholder content</div>
          */}
        </div>
      )}
    </div>
  );
  };
  
  export default Thread;
  