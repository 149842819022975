// src/Chat/ChatMessages.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Chat.module.css';

const ChatMessages = ({ messages }) => {
  console.log('Rendering ChatMessages with messages:', messages);

  return (
    <div className={styles.chatMessages}>
      {messages.map((message, index) => (
        <div
          key={index}
          className={message.sender === 'user' ? styles.messageUser : styles.messageAssistant}
        >
          {message.text || 'No response from assistant'}
        </div>
      ))}
    </div>
  );
};

ChatMessages.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default ChatMessages;
