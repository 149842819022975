import React, { useState } from 'react';
import styles from './ConversationStarters.module.css';

const LoadingAnimation = () => {
  console.log('Rendering LoadingAnimation component');
  return (
    <div className={styles.loadingAnimationOverlay}>
      <dotlottie-player
        src="https://lottie.host/fa33d01e-8694-4f52-a4a7-3c4f21e29d5c/oE9eoWhsBA.json"
        background="transparent"
        speed="1"
        style={{ width: '300px', height: '300px' }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  );
};

const ConversationStarters = ({ onStarterClick }) => {
  console.log('Initializing ConversationStarters component');

  const [loading, setLoading] = useState(false);
  const [showStarters, setShowStarters] = useState(true);

  console.log('Initial state - loading:', loading, 'showStarters:', showStarters);

  const starters = [
    'What does it mean to "walk by the Spirit" as you describe in Galatians 5:16?',
    'What advice do you have for someone struggling with faith, based on your letters in the New Testament?',
    'In 1 Corinthians 13, you talk about love. Can you expand on how this is relevant in modern relationships?',
    'How do you interpret the concept of grace in Ephesians 2:8-9?',
  ];

  const handleStarterClick = async (starter) => {
    setLoading(true);
    setShowStarters(false);

    try {
      // Pass the starter directly to the parent component's handler
      onStarterClick(starter);
    } catch (error) {
      console.error('Error sending conversation starter:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.startersContainer}>
      {loading && <LoadingAnimation />}
      {showStarters && starters.map((starter, index) => (
        <button
          key={index}
          onClick={() => handleStarterClick(starter)}
          className={styles.startersButton}
        >
          {starter}
        </button>
      ))}
    </div>
  );
};

export default ConversationStarters;
