import React, { useState, useEffect, useRef } from 'react';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import styles from './Chat.module.css'; // Importing CSS module
import '@lottiefiles/lottie-player/dist/lottie-player.js'; // Import Lottie Player
import ConversationStarters from '../ConversationStarters/ConversationStarters';

const LoadingAnimation = () => (
  <div className={styles.loadingAnimationOverlay}>
    <dotlottie-player
      src="https://lottie.host/fa33d01e-8694-4f52-a4a7-3c4f21e29d5c/oE9eoWhsBA.json"
      background="transparent"  // Ensure background is transparent
      speed="1"
      style={{ width: '300px', height: '300px' }}
      loop
      autoplay
    ></dotlottie-player>
  </div>
);

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false); // State to track loading status
  const [showConversationStarters, setShowConversationStarters] = useState(true); // State to control conversation starters visibility
  const chatWindowRef = useRef(null); // Ref to the chat window

  const scrollDown = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const sendMessage = async (input) => {
    if (input.trim() === '') return;

    const newUserMessage = { sender: 'user', text: input };
    console.log('User message:', newUserMessage);
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setLoading(true);
    console.log('Loading set to true');
    
    // After sending a message, hide conversation starters
    setShowConversationStarters(false);
    console.log('Conversation starters hidden');

    try {
      const response = await fetch('https://paul-backend-40ce27c1caf4.herokuapp.com/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userQuery: input }),
      });

      const data = await response.json();
      console.log('Assistant response:', data);
      const newAssistantMessage = { sender: 'assistant', text: data.assistantResponse };
      setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);

    } catch (error) {
      console.error('Error fetching assistant response:', error);
    } finally {
      setLoading(false);
      console.log('Loading set to false');
      scrollDown(); // Scroll down after loading is set to false
    }
  };

  const handleStarterClick = async (starter) => {
    if (loading) return; // Prevent multiple clicks while loading
    try {
      const newStarterMessage = { sender: 'user', text: starter };
      console.log('Starter message:', newStarterMessage);
      setMessages((prevMessages) => [...prevMessages, newStarterMessage]);
      setLoading(true);
      console.log('Loading set to true');

      const response = await fetch('https://paul-backend-40ce27c1caf4.herokuapp.com/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userQuery: starter }),
      });

      const data = await response.json();
      console.log('Assistant response:', data);
      const newAssistantMessage = { sender: 'assistant', text: data.assistantResponse };
      setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);

    } catch (error) {
      console.error('Error fetching assistant response:', error);
    } finally {
      setLoading(false);
      console.log('Loading set to false');
      scrollDown(); // Scroll down after loading is set to false
    }
  };

  useEffect(() => {
    scrollDown();
  }, [messages]);

  return (
    <div className={styles.chatContainer}>
      {loading && <LoadingAnimation />} {/* Render LoadingAnimation when loading is true */}
      {showConversationStarters && <ConversationStarters onStarterClick={handleStarterClick} />}
      <div className={styles.chatWindow} ref={chatWindowRef}>
        <ChatMessages messages={messages} />
      </div>
      <ChatInput sendMessage={sendMessage} />
    </div>
  );
};

export default Chat;